import React, { useRef } from "react"
import { useLocation } from "@reach/router"
import {graphql, Link} from "gatsby"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Markdown from "react-markdown"
import Image from "../components/image"
import { StaticImage } from "gatsby-plugin-image"
import Carousel from "react-multi-carousel"

import arrow from "../images/Vector.png"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const Article = ({ data, pageContext }) => {
  const {
    metadata,
    localizations,
    content,
    title,
    mainImage,
    article_author: author,
  } = data.strapiArticle
  const global = data.strapiGlobal
  const location = useLocation()
  const ref = useRef(null)
  const suggestedArticles = data.allStrapiArticle.nodes

  const getDescription = (input = "") => {
    const description = input
      ? input.length > 170
        ? `${input.slice(0, 167)}...`
        : input
      : ""
    return description.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      )
    })
  }

  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <div className="article">
          <div className="article_wrapper">
            <a className="article_back" href={'/blog'}>
              Back
            </a>
            <h1 className="article_title">{title}</h1>
          </div>
          <div className="article_image-wrapper">
            <Image media={mainImage} />
          </div>
          <div className="article_wrapper article_content">
            <Markdown allowDangerousHtml source={content} />
          </div>
          <div className="article_wrapper article_info_wrapper">
            <div className="article_info">
              {author && (
                <div className="article_author">
                  <Image
                    media={author.avatar}
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <div className="article_author_info">
                    <div className="article_author_name">
                      {author.firstName} {author.lastName}
                    </div>
                    <div className="article_author_position">
                      {author.position}
                    </div>
                  </div>
                </div>
              )}
              <button
                className="button-link button-link__inverted article_copy"
                onClick={() => {
                  navigator.clipboard.writeText(location.href)
                }}
              >
                <StaticImage src="../images/copy.png" alt="Copy" />
                Copy post link
              </button>
            </div>
          </div>
        </div>
        <div className="suggested-articles">
          <div className="wrapper">
            <div className="suggested-articles_head">
              <div className="suggested-articles_title">
                You might also like
              </div>
              <div className="suggested-articles_controls">
                <img
                  className="suggested-articles_controls-arrow"
                  src={arrow}
                  role="button"
                  alt="previous slide"
                  onClick={() => ref.current.previous()}
                />
                <img
                  className="suggested-articles_controls-arrow"
                  src={arrow}
                  role="button"
                  alt="next slide"
                  onClick={() => ref.current.next()}
                />
              </div>
            </div>
            <Carousel
              responsive={responsive}
              ref={ref}
              containerClass="carousel-container"
              arrows={false}
              itemClass="article-card_wrapper"
            >
              {suggestedArticles.map(item => {
                return (
                  <Link to={`/blog/${item.url}`} key={item.id}>
                    <div className="article-card">
                      <Image
                          className="article-card_image"
                          media={item.mainImage}
                      />
                      <div className="article-card_info">
                        <div className="article-card_info-title">
                          {item.title}
                        </div>
                        <div className="article-card_info-desc">
                          {getDescription(item.description)}
                        </div>
                      </div>
                      {item.article_author && (
                          <div className="article_author">
                            <Image
                                media={item.article_author.avatar}
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                  objectFit: "cover",
                                }}
                            />
                            <div className="article_author_info">
                              <div className="article_author_name">
                                {item.article_author.firstName}{" "}
                                {item.article_author.lastName}
                              </div>
                              <div className="article_author_position">
                                {item.article_author.position}
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                  </Link>

                )
              })}
            </Carousel>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Article

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    favicon {
      localFile {
        publicURL
      }
    }
    id
    metaTitleSuffix
    metadata {
      metaDescription
      metaTitle
      shareImage {
        localFile {
          publicURL
        }
      }
    }
    footer {
      id
      links {
        id
        newTab
        text
        url
      }
      socials {
        id
        url
        icon {
          alternativeText
          url
        }
        name
      }
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    navbar {
      buttons {
        id
        newTab
        text
        type
        url
      }
      id
      links {
        url
        text
        newTab
        id
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }

  query ArticleQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    allStrapiArticle(
      limit: 6
      filter: { locale: { eq: $locale }, published_at: { ne: null } }
      sort: { fields: updated_at, order: DESC }
    ) {
      nodes {
        id
        url
        title
        description
        mainImage {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        article_author {
          firstName
          lastName
          position
          avatar {
            url
          }
        }
      }
    }
    strapiArticle(id: { eq: $id }) {
      title
      mainImage {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      metadata {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      content
      url
      article_author {
        firstName
        lastName
        position
        avatar {
          url
        }
      }
    }
  }
`
